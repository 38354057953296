import React from 'react'
import { Helmet } from 'react-helmet'
import { useProducts, useProduct } from '@hooks'
import { Container, ProductDetail, MoreProducts } from '@components'

import { DefaultLayout } from '../layouts/defaultlayout'

interface Props {
  pageContext: {
    readonly productId: string
    readonly productName: string
    readonly productSeoDescription: string
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ pageContext }: Props) => {
  const [product, isProductLoaded, productVariants] = useProduct(pageContext.productId)
  const [products, isProductsLoaded] = useProducts()
  return (
    <DefaultLayout isDataLoaded={isProductsLoaded && isProductLoaded}>
      <Helmet>
        <title>{`${pageContext.productName}`}</title>
        <meta name="description" content={pageContext.productSeoDescription} />
      </Helmet>
      <Container>{isProductLoaded && <ProductDetail product={product} variants={productVariants} />}</Container>
      {isProductsLoaded && <MoreProducts products={products} />}
    </DefaultLayout>
  )
}
